import React, { createContext, useReducer } from 'react';
import 'normalize.css';
import './index.css';
import { Navigation, Sidebar, Viewer } from 'components';
import { AppContainer } from './styled';
import actions from 'actions';
import { useWindowSize } from './hooks/useWindowSize';
import MobileHeader from 'components/Mobile/MobileHeader';
import MobileFooter from 'components/Mobile/MobileFooter';
// import { bricksSizesByFormat, redefinePercentages } from 'brickSizes';
import { redefinePercentages } from 'brickSizes';
import { PDF } from 'PDF/PDF';
import { useEffect } from 'react';
import { brickOptions } from 'components/BrickSelector/options';

export const AppContext = createContext(null);

const initialState = {
  currentPage: 0,
  currentSize: '1',
  currentBond: 'half',
  currentSeamType: 'min',
  currentSeamColor: '010',

  isViewerVisible: false,
  isMobileTutorial: true,

  brickValues: [
    {
      type: null,
    },
    {
      type: null,
    },
  ],
  brickRanges: [50],
  totalBrickRanges: [50],
};

export const App = () => {
  const appReducer = (state, action) => {
    switch (action.type) {
      case 'SET_CURRENT_PAGE':
        return { ...state, currentPage: action.payload.currentPage };

      case 'SET_CURRENT_BRICK_SIZE':
        return { ...state, currentSize: action.payload.currentSize };

      case 'SET_CURRENT_BOND':
        return { ...state, currentBond: action.payload.currentBond };

      case 'SET_CURRENT_SEAM_TYPE':
        return { ...state, currentSeamType: action.payload.currentSeamType };

      case 'SET_CURRENT_SEAM_COLOR':
        return { ...state, currentSeamColor: action.payload.currentSeamColor };

      case 'SET_BRICK_VALUES': {
        let size = '1';
        if(action.payload.brickValues instanceof Array && action.payload.brickValues.length > 0) {
          let value = action.payload.brickValues.find((item) => { return item.type !== null; });
          if(value) {
            let brick = brickOptions.find((item) => {
              return (action.payload.brickValues[0].type !== null ? item.type === action.payload.brickValues[0].type : item.type === action.payload.brickValues[1].type);
            });
            if(brick && brick.sizes.length === 1) size = brick.sizes[0];
          }
        }
        return { ...state, brickValues: action.payload.brickValues, currentSize: size };
      }
      case 'SET_BRICK_RANGES':
        return { ...state, brickRanges: action.payload.brickRanges };

      case 'COMMIT_BRICK_RANGES':
        return { ...state, totalBrickRanges: state.brickRanges };

      case 'SET_IS_MOBILE_TUTORIAL':
        return { ...state, isMobileTutorial: action.payload.isMobileTutorial };

      case 'SET_IS_VIEWER_VISIBLE':
        return { ...state, isViewerVisible: action.payload.isViewerVisible };

      case 'ADD_BRICK':
        return {
          ...state,
          brickRanges: [...redefinePercentages(state.brickRanges.length + 1)],
          totalBrickRanges: [...redefinePercentages(state.totalBrickRanges.length + 1)],
        }

      default:
        return state;
    }
  };

  const [appState, dispatch] = useReducer(appReducer, initialState);

  const { width } = useWindowSize();
  const { isViewerVisible, isMobileTutorial } = appState;

  useEffect(() => {
    if (width < 1000) {
      // actions(dispatch).handleChangeIsMobileTutorial(true);
      // actions(dispatch).setCurrentPage(null);
    } else {
      actions(dispatch).handleChangeIsMobileTutorial(false);
      actions(dispatch).setCurrentPage(0);
    }
  }, [width]);

  const shouldRenderSidebar = () => {
    if (width > 1000) return true;

    return !isViewerVisible && !isMobileTutorial;
  };

  const shouldRenderViewer = () => {
    if (width > 1000) return true;

    return isViewerVisible || isMobileTutorial;
  };

  return (
    <AppContainer id='app-container'>
      <AppContext.Provider value={{ ...appState, ...actions(dispatch) }}>
        <Navigation />

        <MobileHeader isViewerVisible={isViewerVisible} isMobileTutorial={isMobileTutorial} />

        {shouldRenderSidebar() && <Sidebar />}
        {shouldRenderViewer() && <Viewer context={{ ...appState, ...actions(dispatch) }} />}

        <MobileFooter />

        <PDF />
      </AppContext.Provider>
    </AppContainer>
  );
}
