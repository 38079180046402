import React, { useContext, memo, useCallback } from 'react';
import { SidebarContainer } from './styled';

import BrickSelector from 'components/BrickSelector';
import SizeSelector from 'components/SizeSelector';
import SeamSelector from 'components/SeamSelector';
import BondSelector from 'components/BondSelector';

import { AppContext } from 'App';
import equals from 'ramda/es/equals';

export const Sidebar = memo(() => {
  const {
    currentPage = 0,
    currentSize,
    currentBond,
    currentSeamType,
    currentSeamColor,
    brickValues,
    brickRanges,

    handleChangeBrickValues,
    handleChangeBrickRanges,
    handleCommitBrickRanges,
    handleChangeSize,
    handleChangeSeamType,
    handleChangeSeamColor,
    handleChangeBond,
    handleAddBrick,
  } = useContext(AppContext);

  const handleChangeBrickType = useCallback((data) => {
    handleChangeBrickValues(data);
  }, [handleChangeBrickValues]);

  const handleChangeBrickRange = useCallback((data) => {
    handleChangeBrickRanges(data);
  }, [handleChangeBrickRanges]);

  const handleCommitBrickRange = useCallback(() => {
    handleCommitBrickRanges();
  }, [handleCommitBrickRanges]);

  const components = [
    <BrickSelector
      brickValues={brickValues}
      brickRanges={brickRanges}
      onChangeBrick={handleChangeBrickType}
      onChangeRange={handleChangeBrickRange}
      onCommitRange={handleCommitBrickRange}
      onAddBrick={handleAddBrick}
    />,
    <SizeSelector
      currentSize={currentSize}
      brickValues={brickValues}
      onChangeSize={handleChangeSize}
    />,
    <SeamSelector
      currentSeamType={currentSeamType}
      currentSeamColor={currentSeamColor}
      onChangeSeamType={handleChangeSeamType}
      onChangeSeamColor={handleChangeSeamColor}
    />,
    <BondSelector
      currentBond={currentBond}
      onChangeBond={handleChangeBond}
    />,
  ];

  return (
    <SidebarContainer>{components[currentPage]}</SidebarContainer>
  );
}, equals);
