import React, { useState, useCallback, memo } from 'react';
import {
  BondOption, BondOptionIconContainer, ChooseBondButton, BondOptions,
  BondSelectorContainer, BondOptionFirstLabel, BondOptionText,
} from './styled';
import { bondOptions } from './options';
import equals from 'ramda/es/equals';

export const BondSelector = memo(({ onChangeBond, currentBond }) => {
  const handleClickOnOption = useCallback((bond) => () => {
    onChangeBond(bond);
  }, [onChangeBond]);

  const renderOptions = bondOptions.map(({ icon, firstLabel, value }) => {
    // eslint-disable-next-line
    const [isButtonVisible, setIsButtonVisible] = useState(false);
    const isActiveOption = value === currentBond;

    const handleMouseEnterOption = () => {
      setIsButtonVisible(true);
    };

    const handleMouseLeaveOption = () => {
      setIsButtonVisible(false);
    };

    return (
      <BondOption
        key={value}
        isActive={isActiveOption}
        onClick={handleClickOnOption(value)}
        onMouseEnter={handleMouseEnterOption}
        onMouseLeave={handleMouseLeaveOption}
      >
        <BondOptionIconContainer isActive={isActiveOption}>
          {(isButtonVisible && !isActiveOption) && <ChooseBondButton>Выбрать</ChooseBondButton>}
          {icon}
        </BondOptionIconContainer>

        <BondOptionText>
          <BondOptionFirstLabel isActive={isActiveOption}>{firstLabel}</BondOptionFirstLabel>
        </BondOptionText>
      </BondOption>
    );
  });

  return (
    <BondSelectorContainer>
      <BondOptions>
        {renderOptions}
      </BondOptions>
    </BondSelectorContainer>
  );
}, equals); 
