import styled, { css } from 'styled-components';
import { ifProp } from 'styled-tools';

export const NavigationContainer = styled.div`
  flex-basis: 5.3125rem;
  max-width: 5.3125rem;

  display: flex;
  flex-shrink: 0;
  flex-grow: 0;
  flex-direction: column;

  height: 100%;

  background: linear-gradient(180deg, #48484F 0%, #313138 100%);

  @media screen and (max-width: 1000px) {
    display: none;
  }
`;

export const LogoBlock = styled.div`
  display: flex;
  justify-content: center;

  flex-grow: 0;
  flex-shrink: 0;
`;

export const Logo = styled.img`
  margin-top: 1.5rem;
`;

export const NavigationButtons = styled.ul`
  padding: 0;

  flex-grow: 0;
  flex-shrink: 0;
`;

export const NavigationItem = styled.li`
  list-style-type: none;
  width: 100%;
`; 

export const NavigationButton = styled.button`
  width: 100%;
  height: 4.1875rem;
  padding: 0;

  display: flex;
  flex-direction: column;
  align-items: center;

  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;

  ${ifProp({ active: true }, css`
    background: #262626;

    ${ifProp({ fillIcon: false }, css`
      div svg * {
        stroke: #C85F37;
      }
    `)}

    ${ifProp({ fillIcon: true }, css`
      div svg * {
        fill: #C85F37;
      }
    `)}
  `)} 
`;

export const ButtonLogo = styled.div`
  margin-top: 1.1875rem;
`;

export const ButtonText = styled.p`
  margin: 0;
  margin-top: .3125rem;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: .875rem;
  text-align: center;
  text-transform: uppercase;

  color: #FFFFFF;
`;

export const DownloadBlock = styled.div`
  flex-grow: 1;

  display: flex;
  flex-direction: column;

  justify-content: flex-end;
`;

export const DownloadButton = styled.button`
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  width: 100%;
  flex-basis: 3.9375rem;

  display: flex;
  flex-direction: column;

  align-items: center;

  :hover {
    * {
      fill: #c4c4c4;
    }
  }

  ${({ isActive }) => isActive && css`
    background-color: #262626;

    * {
      fill: #c85f37;
      color: white;
    }
  `}
`;

export const DownloadLogoBlock = styled.div`
  margin-bottom: .5rem;
`;

export const DownloadButtonText = styled.p`
  margin: 0;
  margin-bottom: 1rem;
  text-transform: uppercase;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .75rem;
  line-height: .875rem;
  text-align: center;

  color: #FFFFFF;

  opacity: 1;
`;
