import React from 'react';
import pdfLogo from './pdf-logo.png';
import {
  HeaderContainer, HeaderLogoBlock, HeaderLogo, HeaderTextBlock,
  HeaderTextTitle, HeaderTextAddress, HeaderTextContacts,
} from './styled';

export const Header = () => (
  <HeaderContainer>
    <HeaderLogoBlock>
      <HeaderLogo src={pdfLogo} />
    </HeaderLogoBlock>

    <HeaderTextBlock>
      <HeaderTextTitle>Контактная информация:</HeaderTextTitle>

      <HeaderTextAddress>Нижегородская обл., Кстовский район, д. Афонино, ул. Магистральная, 137В</HeaderTextAddress>

      <HeaderTextContacts>+7 831 262-20-79</HeaderTextContacts>
    </HeaderTextBlock>
  </HeaderContainer>
);