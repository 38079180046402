import pshenSmooth from './images/pshen-1-smooth.jpg';
import pshenVelvet from './images/pshen-1-velvet.jpg';
import pshenBossage from './images/pshen-1-bossage.jpg';

import silverSmooth from './images/silver-1-smooth.jpg';
import silverVelvet from './images/silver-1-velvet.jpg';

import classicRedSmooth from './images/classic-red-1-smooth.jpg';
import classicRedVelvet from './images/classic-red-1-velvet.jpg';
import classicRedBossage from './images/classic-red-1-bossage.jpg';

import burgundySmooth from './images/bordo-1-smooth.jpg';
import burgundyVelvet from './images/bordo-1-velvet.jpg';
import burgundyBossage from './images/bordo-1-bossage.jpg';

import burgundyDarkSmooth from './images/bordo-dark-1-smooth.jpg';

import terracotaSmooth from './images/terracota-1-smooth.jpg';
import terracotaVelvet from './images/terracota-1-velvet.jpg';
import terracotaBossage from './images/terracota-1-bossage.jpg';

import terracottaLightSmooth from './images/terracotta-light-smooth.jpg';

import chocolateSmooth from './images/chocolate-1-smooth.jpg';
import chocolateVelvet from './images/chocolate-1-velvet.jpg';
import chocolateBossage from './images/chocolate-1-bossage.jpg';

import russetGranite from './images/russet-granite.jpg';
import lavaHard from './images/lava-hard.jpg';
import oliveStrong from './images/olive-strong.jpg';
import marsStrong from './images/mars-strong.jpg';
import mercuryStrong from './images/mercury-strong.jpg';
import brownGranite from './images/brown-granit.jpg';
import brownDiamonds from './images/brown-diamonds.jpg';
import fusionEven from './images/fussion-even.jpg';
import fusionStrong from './images/fussion-strong.jpg';
import fusionVelour from './images/fussion-vellure.jpg';
import beroza from './images/beroza.jpg';
import akatsia from './images/akatsia.jpg';
import platinum from './images/platinum.jpg';
import ruby from './images/ruby.jpg';

import kermaPremiumKepler from './images/kerma-premium-kepler.jpg';
import kermaPremiumSaturn from './images/kerma-premium-saturn.jpg';
import kermaPremiumSequoia from './images/kerma-premium-sequoia.jpg';

import britanikaBery from './images/britanika-bery.jpg';
import britanikaEden from './images/britanika-eden.jpg';
import britanikaEsk from './images/britanika-esk.jpg';
import britanikaKent from './images/britanika-kent.jpg';
import britanikaOldham from './images/britanika-oldham.jpg';
import britanikaStafford from './images/britanika-stafford.jpg';

import jupiter from './images/jupiter.jpg';
import luna from './images/luna.jpg';
import neptune from './images/neptune.jpg';
import ork from './images/ork.jpg';
import protey from './images/protey.jpg';


const imagePathStart = `${process.env.PUBLIC_URL}/images`;

export const brickOptions = [
  {
    sizes: ['1', '0.7'],
    label: 'JUPITER',
    type: 'jupiter',
    image: jupiter,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/jupiter/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'LUNA',
    type: 'luna',
    image: luna,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/luna/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'NEPTUNE',
    type: 'neptune',
    image: neptune,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/neptune/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'ORK',
    type: 'ork',
    image: ork,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/ork/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'PROTEY',
    type: 'protey',
    image: protey,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/protey/${index + 1}.jpg`),
    isPremium: 'premium',
  },

  {
    sizes: ['WDF'],
    label: 'BERY',
    type: 'britanika-bery',
    image: britanikaBery,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-bery/${index + 1}.png`),
    isPremium: 'premium',
  },
  {
    sizes: ['WDF'],
    label: 'EDEN',
    type: 'britanika-eden',
    image: britanikaEden,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-eden/${index + 1}.png`),
    isPremium: 'premium',
  },
  {
    sizes: ['WDF'],
    label: 'ESK',
    type: 'britanika-esk',
    image: britanikaEsk,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-esk/${index + 1}.png`),
    isPremium: 'premium',
  },
  {
    sizes: ['WDF'],
    label: 'KENT',
    type: 'britanika-kent',
    image: britanikaKent,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-kent/${index + 1}.png`),
    isPremium: 'premium',
  },
  {
    sizes: ['WDF'],
    label: 'OLDHAM',
    type: 'britanika-oldham',
    image: britanikaOldham,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-oldham/${index + 1}.png`),
    isPremium: 'premium',
  },
  {
    sizes: ['WDF'],
    label: 'STAFFORD',
    type: 'britanika-stafford',
    image: britanikaStafford,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/britanika-stafford/${index + 1}.png`),
    isPremium: 'premium',
  },

  {
    sizes: ['1', '0.7'],
    label: 'RUBY',
    type: 'ruby',
    image: ruby,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/ruby/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'PLATINUM',
    type: 'platinum',
    image: platinum,
    imagesPaths: new Array(10).fill(null).map((_, index) => `${imagePathStart}/platinum/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'RUSSET GRANITE',
    type: 'russet-granite',
    image: russetGranite,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/russet-granite/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'LAVA HARD',
    type: 'lava-hard',
    image: lavaHard,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/lava-hard/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'BROWN GRANITE',
    type: 'brown-granite',
    image: brownGranite,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/brown-granite/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'BROWN DIAMONDS',
    type: 'brown-diamonds',
    image: brownDiamonds,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/brown-diamonds/${index + 1}.jpg`),
    isPremium: 'premium',
  },

  {
    sizes: ['1', '0.7'],
    label: 'FUSION EVEN',
    type: 'fusion-even',
    image: fusionEven,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/fusion-even/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'FUSION STRONG',
    type: 'fusion-strong',
    image: fusionStrong,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/fusion-strong/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'FUSION VELOUR',
    type: 'fusion-velour',
    image: fusionVelour,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/fusion-velour/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'OLIVE STRONG',
    type: 'olive-strong',
    image: oliveStrong,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/olive-strong/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1'],
    label: 'KEPLER',
    type: 'kerma-premium-kepler',
    image: kermaPremiumKepler,
    imagesPaths: new Array(9).fill(null).map((_, index) => `${imagePathStart}/kerma-premium-kepler/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'SATURN',
    type: 'kerma-premium-saturn',
    image: kermaPremiumSaturn,
    imagesPaths: new Array(9).fill(null).map((_, index) => `${imagePathStart}/kerma-premium-saturn/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1'],
    label: 'SEQUOIA',
    type: 'kerma-premium-sequoia',
    image: kermaPremiumSequoia,
    imagesPaths: new Array(9).fill(null).map((_, index) => `${imagePathStart}/kerma-premium-sequoia/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'MARS STRONG',
    type: 'mars-strong',
    image: marsStrong,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/mars-strong/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'MERCURY STRONG',
    type: 'mercury-strong',
    image: mercuryStrong,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/mercury-strong/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'BERÖZA HARD',
    type: 'beroza-hard',
    image: beroza,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/beroza-hard/${index + 1}.jpg`),
    isPremium: 'premium',
  },
  {
    sizes: ['1', '0.7'],
    label: 'AKATSIA',
    type: 'akatsia',
    image: akatsia,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/akatsia/${index + 1}.jpg`),
    isPremium: 'premium',
  },

  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Пшеничное лето (гладкий)',
    type: 'pshen-smooth',
    image: pshenSmooth,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Пшеничное лето (бархат)',
    type: 'pshen-velvet',
    image: pshenVelvet,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-velvet-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-velvet-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Пшеничное лето (рустик)',
    type: 'pshen-bossage',
    image: pshenBossage,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-bossage-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/pshen-bossage-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },

  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Серебро (гладкий)',
    type: 'silver-smooth',
    image: silverSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/silver-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/silver-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Серебро (бархат)',
    type: 'silver-velvet',
    image: silverVelvet,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/silver-velvet-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/silver-velvet-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },

  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Классический красный (гладкий)',
    type: 'classic-red-smooth',
    image: classicRedSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Классический красный (бархат)',
    type: 'classic-red-velvet',
    image: classicRedVelvet,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-velvet-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-velvet-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Классический красный (рустик)',
    type: 'classic-red-bossage',
    image: classicRedBossage,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-bossage-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/classic-red-bossage-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Бордо (гладкий)',
    type: 'burgundy-smooth',
    image: burgundySmooth,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/burgundy-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/burgundy-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1'],
    label: 'Бордо (бархат)',
    type: 'burgundy-velvet',
    image: burgundyVelvet,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/burgundy-velvet-1/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1'],
    label: 'Бордо (рустик)',
    type: 'burgundy-bossage',
    image: burgundyBossage,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/burgundy-bossage-1/${index + 1}.jpg`),
    isPremium: 'kerma',
  },

  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Бордо тёмный (гладкий)',
    type: 'burgundy-dark-smooth',
    image: burgundyDarkSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/burgundy-dark-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/burgundy-dark-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },

  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Терракот (гладкий)',
    type: 'terracotta-smooth',
    image: terracotaSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/terracotta-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/terracotta-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Терракот (бархат)',
    type: 'terracotta-velvet',
    image: terracotaVelvet,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/terracotta-velvet-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/terracotta-velvet-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1'],
    label: 'Терракот (рустик)',
    type: 'terracotta-bossage',
    image: terracotaBossage,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/terracotta-bossage-1/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Терракот светлый (гладкий)',
    type: 'terracotta-light-smooth',
    image: terracottaLightSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/terracotta-light-smooth-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/terracotta-light-smooth-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1'],
    label: 'Шоколад (гладкий)',
    type: 'chocolate-smooth',
    image: chocolateSmooth,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/chocolate-smooth-1/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Шоколад (бархат)',
    type: 'chocolate-velvet',
    image: chocolateVelvet,
    imagesPaths: new Array(12).fill(null).map((_, index) => `${imagePathStart}/chocolate-velvet-1/${index + 1}.jpg`),
    imagesPaths14: new Array(12).fill(null).map((_, index) => `${imagePathStart}/chocolate-velvet-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
  {
    sizes: ['0.7', '1', '1.4'],
    label: 'Шоколад (рустик)',
    type: 'chocolate-bossage',
    image: chocolateBossage,
    imagesPaths: new Array(3).fill(null).map((_, index) => `${imagePathStart}/chocolate-bossage-1/${index + 1}.jpg`),
    imagesPaths14: new Array(3).fill(null).map((_, index) => `${imagePathStart}/chocolate-bossage-14/${index + 1}.jpg`),
    isPremium: 'kerma',
  },
];

export const scales = [
  {
    label: '10',
    value: 10,
  },
  {
    label: '20',
    value: 10,
  },
  {
    label: '1/4',
    value: 5,
    isBold: true,
  },
  {
    label: '30',
    value: 5,
  },
  {
    label: '1/3',
    value: 3.333,
    isBold: true,
  },
  {
    label: '40',
    value: 6.67,
  },
  {
    label: '1/2',
    value: 10,
    isBold: true,
  },
  {
    label: '60',
    value: 10,
  },
  {
    label: '2/3',
    value: 6.666,
    isBold: true,
  },
  {
    label: '70',
    value: 3.334,
  },
  {
    label: '3/4',
    value: 5,
    isBold: true,
  },
  {
    label: '80',
    value: 5,
  },
  {
    label: '90',
    value: 10,
  }
];
