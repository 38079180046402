import React, { memo } from 'react';
import { default as VendorLoader } from 'react-loader-spinner'
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';
import { LoaderContainer, LoaderText } from './styled';

export const Loader = memo(({ isVisible, loaderText }) => {
  return isVisible && (
    <LoaderContainer>
      <VendorLoader
        type='Rings'
        color='#C85F37'
        height={100}
        width={100}
        visible={isVisible}
      />

      <LoaderText>{loaderText}</LoaderText>
    </LoaderContainer>
  );
});
