import styled, { css } from 'styled-components';
import { ifProp, ifNotProp } from 'styled-tools';

export const BondSelectorContainer = styled.div`
  background: #ECECEC;

  margin: .75rem .75rem .8125rem .9375rem;

  height: calc(100% - 1.5675rem);
  width: 100%;
`;

export const BondOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const BondOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.875rem;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      background: rgb(200, 95, 55, 0.2);
    }
  `)}
`;

export const BondOptionIconContainer = styled.div`
  flex-basis: 6.75rem;
  flex-grow: 0;
  flex-shrink: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    .marked {
      fill: #46464D;
    }

    .main {
      fill: #8B8B8E;
    }
  }

  ${ifProp({ isActive: true }, css`
    svg .main {
      fill: #C85F37;
    }
  `)}
`;

export const BondOptionText = styled.div`
  display: flex;
  align-items: center;

  flex-basis: 4.96875rem;
  flex-grow: 1;
  flex-shrink: 0;
`;

export const BondOptionFirstLabel = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #000000;
  margin: 0;
  
  ${ifProp({ isActive: true }, css`
    color: #C85F37;
  `)}
`;

export const BondOptionSecondLabel = styled(BondOptionFirstLabel)`
  margin-top: 0.4375rem;
`;

export const ChooseBondButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #C85F37;
  border-radius: 0.1875rem;

  cursor: pointer;
  position: absolute;
`;
