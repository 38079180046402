import styled from 'styled-components';
import { Logo, DownloadButtonText, DownloadLogoBlock, DownloadButton } from 'components/Navigation/styled';

export const MobileHeaderContainer = styled.div`
  display: none;
  
  flex-basis: 3.4375rem;
  max-height: 3.4375rem;
  flex-grow: 0;
  flex-shrink: 0;
  background: linear-gradient(180deg, #48484F 0%, #313138 100%);
  justify-content: center;

  ${Logo} {
    margin-top: 0;
    margin-left: 1.125rem;
  }

  @media screen and (max-width: 1000px) {
    display: flex; 
  }
`;

export const MobileHeaderInner = styled.div`
  width: 23.4375rem;

  display: flex;
  align-items: center;

  ${DownloadButton} {
    min-width: 5.25rem;
    height: 100%;
    display: flex;
    justify-content: center;
  }

  ${DownloadButtonText} {
    margin-bottom: 0;
  }

  ${DownloadLogoBlock} {
    margin-bottom: .2rem;

    svg {
      height: 1.5rem;
    }
  }

  ${DownloadButtonText} {
    font-size: .5625rem;
  }
`;