import styled, { css } from 'styled-components';
import { ifNotProp, ifProp } from 'styled-tools';

export const SeamSelectors = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
`;

export const SeamTypeSelectorContainer = styled.div`
  background: #ECECEC;

  margin: .75rem .75rem .625rem .9375rem;

  display: flex;
  flex-direction: column;
  min-height: 179px;
`;

export const SeamSelectorHeader = styled.div`
  height: 2.25rem;
  display: flex;
  position: sticky;
  background: #ECECEC;
  top: 0;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);
  flex-shrink: 0;
`;

export const SeamSelectorHeaderText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;
  margin: 0;

  color: #46464D;
  margin-left: .8125rem;
  margin-top: .625rem;
`;

export const SeamColorSelectorContainer = styled(SeamTypeSelectorContainer)`
  margin-top: 0;
  margin-bottom: 1rem;

  min-height: 100%;
`;

export const SeamTypeOptions = styled.ul`
  padding: 0;
  margin: 0;
`;

export const SeamOption = styled.li`
  list-style-type: none;

  cursor: pointer;

  display: flex;
  height: 4.6875rem;
  border-bottom: 1px solid rgb(61, 61, 68, 0.5);

  ${ifNotProp({ isActive: true }, css`
    &:hover {
      background: rgb(200, 95, 55, 0.2);
    }
  `)}
`;

export const SeamOptionIconContainer = styled.div`
  flex-basis: 6.75rem;
  flex-grow: 0;
  flex-shrink: 0;

  svg {
    margin-top: 1.375rem;
    margin-left: 2.25rem;
  }

  svg path {
    fill: #8b8b8e;
  }

  ${ifProp({ isActive: true }, css`
    svg path {
      fill: #c85f37;
    }
  `)}
`;

export const SeamOptionText = styled.div`
  display: flex;
  flex-direction: column;

  flex-basis: 4.96875rem;
  flex-grow: 1;
  flex-shrink: 0;

  margin-left: 2rem;
`;

export const SeamOptionLabel = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #000000;
  margin: 0;
  
  margin-top: 1.375rem;

  ${ifProp({ isActive: true }, css`
    color: #C85F37;
  `)}
`;

export const ChooseSeamTypeButton = styled.button`
  width: 5rem;
  height: 1.5rem;
  border: none;
  outline: none;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  color: #FFFFFF;

  background: #C85F37;
  border-radius: 0.1875rem;

  position: absolute;
  cursor: pointer;
  margin-top: 1.5rem;
  margin-left: 1.7rem;
`;

export const SeamColorOptions = styled(SeamTypeOptions)`
  min-height: 100%;
`;

export const SeamColorOption = styled(SeamOption)`
  align-items: center;
  height: 3.0625rem;
`;

export const SeamColor = styled.div`
  width: 2rem;
  height: 2rem;

  flex-grow: 0;
  flex-shrink: 0;

  background: url(${({ seamImage }) => seamImage});
  box-sizing: border-box;
  margin-left: .875rem;
  margin-right: 1.1875rem;

  ${ifProp({ isActive: true }, css`
    border: 2px solid #C85F37;
  `)}
`;

export const SeamColorText = styled.p`
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: .875rem;
  line-height: 1rem;

  flex-grow: 0;
  flex-shrink: 0;

  color: #000000;

  ${ifProp({ isActive: true }, css`
    color: #C85F37;
  `)}
`;

export const SeamColorButtonContainer = styled.div`
  flex-grow: 1;

  display: flex;
  justify-content: flex-end;
`;

export const SeamColorButton = styled(ChooseSeamTypeButton)`
  margin: 0;
  position: static;

  margin-right: 1rem;
  width: 5rem;
  height: 1.5rem;
`;
