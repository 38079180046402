import styled from 'styled-components';

export const LoaderContainer = styled.div`
  z-index: 999;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);

  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;

  width: 220px;
  height: 170px;

  display: flex;
  justify-content: center;
  flex-direction: column;

  div {
    display: flex;
    justify-content: center;
  }
`;

export const LoaderText = styled.p`
  font-family: Roboto;
  font-size: 1rem;
  text-align: center;
  margin: 0;
  margin-top: .5rem;
  color: white;
`;
