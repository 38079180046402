import styled from 'styled-components';
import { KermaPremiumImage, ChooseItemOptionImage } from 'components/BrickSelector/styled';

export const TableHead = styled.div`
  border-bottom: 1px solid rgb(36,32,33, 0.5);

  min-height: 41px;
`;

export const TableHeadRow = styled.div`
  height: 40px;

  margin-bottom: 15px;
`;

export const TableHeadCell = styled.div`
  height: 100%;
  overflow: visible; 

  display: flex !important;
  align-items: flex-end;

  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 100%;
  color: #000000;

  p {
    margin: 0;
    width: 150px;

    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 100%;
    color: #000000;
  }
`;

export const TableBody = styled.div``;

export const TableBodyRow = styled.div`
  min-height: 61px;
  border-bottom: 1px solid rgb(36,32,33, 0.5);
  width: 100% !important;
`;

export const TableBodyCell = styled.div`
  display: flex !important;
  align-items: center;

  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;

  color: #000000;

  ${ChooseItemOptionImage} {
    min-width: 102px;
    width: 102px;
    height: 32px;
  }

  ${KermaPremiumImage} {
    margin-left: 2.8125rem;
  }
`;
