import styled from 'styled-components';

export const SidebarContainer = styled.aside`
  position: relative;
  overflow: hidden;
  flex-basis: 19.625rem;
  flex-shrink: 0;
  flex-grow: 0;

  display: flex;

  background: #262626;

  @media screen and (max-width: 1000px) {
    flex-basis: 70%;
    flex-grow: 1;

    & > div {
      justify-content: center;
    }
  }
`;
