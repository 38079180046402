import React, { useMemo, memo } from 'react';
import { useTable, useBlockLayout } from 'react-table';
import { TableHead, TableBody, TableHeadRow, TableHeadCell, TableBodyCell, TableBodyRow } from './styled';
import { KermaPremiumImage, ChooseItemOptionImage } from 'components/BrickSelector/styled';
import kermaPremium from 'components/BrickSelector/kerma-premium.svg';
import britanica from 'components/BrickSelector/britanica.png';
import kerma from 'components/BrickSelector/kerma.png';
import equals from 'ramda/es/equals';

export const Table = memo(({ isWithImages, data = [] }) => {
  const imageColumn = isWithImages
    ? [
      {
        Header: () => <p>Название (SKU)</p>,
        Cell: ({ cell: { value: image, row: { original: { isPremium } } } }) => (
          <>
            {isPremium === 'premium' && <KermaPremiumImage src={kermaPremium} />}
            {isPremium === 'britanica' && <KermaPremiumImage src={britanica} />}
            {isPremium === 'kerma' && <KermaPremiumImage src={kerma} />}

            <ChooseItemOptionImage src={image} />
          </>
        ),
        accessor: 'image',

        width: 130,
      },
    ]
    : [
      {
        Header: '№',
        accessor: 'indexOfBrick',
        width: 50,
      }
    ];

  const columns = useMemo(
    () => [
      ...imageColumn,
      {
        Header: isWithImages ? '' : 'Название (SKU)',
        accessor: 'name',

        width: isWithImages ? 331 : 414,
      },
      {
        Header: 'Размер',
        accessor: 'size',
        width: 154,
      },
      {
        Header: '%',
        accessor: 'percent',
        width: 118,
      },
      {
        Header: () => <span>шт / м<sup>2</sup></span>,
        accessor: 'count',
        width: 54,
      }
    ],
    // eslint-disable-next-line
    [],
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({ columns, data }, useBlockLayout);

  return (
    <div {...getTableProps()} className="table">
      <TableHead>
        {headerGroups.map((headerGroup) => (
          <TableHeadRow {...headerGroup.getHeaderGroupProps()} className="tr">
            {headerGroup.headers.map(column => (
              <TableHeadCell {...column.getHeaderProps()} className="th">
                {column.render('Header')}
              </TableHeadCell>
            ))}
          </TableHeadRow>
        ))}
      </TableHead>

      <TableBody {...getTableBodyProps()}>
        {rows.map(
          (row, i) => {
            prepareRow(row);
            return (
              <TableBodyRow {...row.getRowProps()} className="tr">
                {row.cells.map(cell => {
                  return (
                    <TableBodyCell {...cell.getCellProps()} className="td">
                      {cell.render('Cell')}
                    </TableBodyCell>
                  )
                })}
              </TableBodyRow>
            )
          }
        )}
      </TableBody>
    </div>
  );
}, equals);
